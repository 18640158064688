import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

import SocialLink from "./SocialLink";

export default ({ light, noBorder, highlightColor, lowlightColor }) => (
  <StaticQuery
    query={detailsQuery}
    render={data => {
      const social = data.site.siteMetadata.social;

      return (
        <React.Fragment>
          <SocialLink
            light={light}
            noBorder={noBorder}
            highlightColor={highlightColor}
            lowlightColor={lowlightColor}
            url={`https://www.instagram.com/${social.instagram}`}
            icon={faInstagram}
          />
          <SocialLink
            light={light}
            noBorder={noBorder}
            highlightColor={highlightColor}
            lowlightColor={lowlightColor}
            url={`https://www.youtube.com/channel/${social.youtube}`}
            icon={faYoutube}
          />
        </React.Fragment>
      );
    }}
  />
);

const detailsQuery = graphql`
  query DefaultSocialLinksQuery {
    site {
      siteMetadata {
        social {
          instagram
          youtube
        }
      }
    }
  }
`;
