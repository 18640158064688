import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Link = styled.a`
  display: flex;
  align-items: center;
  color: ${props =>
    props.lowlightColor ? props.lowlightColor : props.light ? "#fff" : "#000"};
  padding: 5px 10px;
  border: ${props => (props.noBorder ? "none" : "1px solid #d5d5d5")};
  border-radius: 3px;
  height: 40px;
  &:hover {
    color: ${props =>
      props.highlightColor
        ? props.highlightColor
        : props.light
        ? "gold"
        : "#fff"};
    background: ${props => (props.noBorder ? "none" : "#016597")};
    border-color: ${props => (props.light ? "gold" : "#016597")};
  }
  & + & {
    margin-left: 10px;
  }
  ${props => {
    if (!props.labeled) {
      return css`
        width: 40px;
        justify-content: center;
      `;
    }
    return css`
      ${props => props.theme.breakpoints.down("sm")} {
        width: 40px;
        justify-content: center;
      }
    `;
  }}
`;

const Label = styled.span`
  margin-left: 5px;
  ${props => props.theme.breakpoints.down("sm")} {
    display: none;
  }
`;

export default ({
  url,
  icon,
  label,
  light,
  noBorder,
  highlightColor,
  lowlightColor
}) => {
  return (
    <Link
      light={light}
      highlightColor={highlightColor}
      lowlightColor={lowlightColor}
      noBorder={noBorder}
      labeled={!!label}
      href={url}
      rel={"noopener"}
      target={"_blank"}
    >
      <FontAwesomeIcon icon={icon} />
      {label && <Label>{label}</Label>}
    </Link>
  );
};
