import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  color: #000;
  letter-spacing: 0.1rem;
  margin-bottom: 20px;
`;

const Addr = styled.div`
  margin-top: 10px;
  display: flex;
  font-weight: bold;
  font-size: 14px;
`;

const Sub = styled.div`
  display: flex;
  font-size: 14px;
`;

export default () => {
  return (
    <Container>
      <Title>{"Direcciones"}</Title>
      <Map />
      <Addr>{"Avda. Pedro Fontova 4957"}</Addr>
      <Sub>{"Conchalí"}</Sub>
      <Sub>{"Región Metropolitana"}</Sub>
    </Container>
  );
};

const Map = () => {
  return (
    <iframe
      title={"Direcciones"}
      width="100%"
      height="300px"
      style={{ border: 0 }}
      src={
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3331.6019144422203!2d-70.6748102!3d-33.3814575!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c6f451843fdd%3A0x201e7a2004fecc04!2sCatedral+Evangelica+Conchal%C3%AD!5e0!3m2!1sen!2scl!4v1564852005865!5m2!1sen!2scl"
      }
      allowFullScreen
    />
  );
};
