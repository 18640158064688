import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import Logo from "!svg-react-loader?name=Logo!../content/vectors/logo.svg";

const Container = styled.div`
  display: block;
  position: relative;
  margin-top: 40px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: ${props => props.theme.shadows[5]};
`;

const Block = styled.div`
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
  height: 80px;
  width: 200px;
  background-color: #fff;
`;

const StyledLogo = styled(Logo)`
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80px;
  width: 80px;
`;

export default () => (
  <Container>
    <Block />
    <Link to={"/"}>
      <StyledLogo />
    </Link>
  </Container>
);
