import React from "react";
import styled from "styled-components";
import Grid from "@tleef/react-grid";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  color: #000;
  letter-spacing: 0.1rem;
  margin-bottom: 20px;
`;

const Day = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const Desc = styled.div`
  font-size: 14px;
`;

const dayWidth = 3.5;
const descWidth = 8.5;

export default () => {
  return (
    <Container>
      <Title>{"Días de Reunión"}</Title>
      <Grid container spacing={10}>
        <Grid item xs={dayWidth}>
          <Day>Lunes y Jueves</Day>
        </Grid>
        <Grid item xs={descWidth}>
          <Desc>Reunión en todas nuestras clases a las 19:00 hrs.</Desc>
        </Grid>
        <Grid item xs={dayWidth}>
          <Day>Martes</Day>
        </Grid>
        <Grid item xs={descWidth}>
          <Desc>Reunión de hermanos Voluntarios a las 19:30 hrs.</Desc>
        </Grid>
        <Grid item xs={dayWidth}>
          <Day>Miércoles</Day>
        </Grid>
        <Grid item xs={descWidth}>
          <Desc>Reunión de hermanas Dorcas a las 17:00 hrs.</Desc>
        </Grid>
        <Grid item xs={dayWidth}>
          <Day>Viernes</Day>
        </Grid>
        <Grid item xs={descWidth}>
          <Desc>Reunión General Templo Catedral a las 19:00 hrs.</Desc>
        </Grid>
        <Grid item xs={dayWidth}>
          <Day>Domingo</Day>
        </Grid>
        <Grid item xs={descWidth}>
          <Desc>
            Escuela Dominical a las 10:30 y reunión General a las 18:00 hrs.
          </Desc>
        </Grid>
      </Grid>
    </Container>
  );
};
