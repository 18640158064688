import React from "react";
import styled, { css } from "styled-components";

import NavItem from "./NavItem";

const Bar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: #016597;
  z-index: ${props => props.theme.zIndex.appBar - 1};
  box-shadow: ${props => props.theme.shadows[5]};
  transition: ${props => props.theme.transitions.create()};
  top: 80px;

  @media (max-width: 649.95px) {
    display: none;
  }

  ${props =>
    props.isHidden &&
    css`
      top: 0px;
    `}
`;

const Center = styled.div`
  height: 60px;
  width: 60px;
`;

const Left = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 400px;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 400px;
`;

export default class Nav extends React.Component {
  render() {
    const { hide } = this.props;

    return (
      <Bar isHidden={hide}>
        <Left>
          <NavItem url={"/"}>Inicio</NavItem>
          <NavItem url={"/nuestros-templos"}>Nuestros Templos</NavItem>
          <NavItem url={"/coros"}>Coros</NavItem>
        </Left>
        <Center />
        <Right>
          <NavItem url={"/galeria"}>Galería</NavItem>
          <NavItem url={"/estudios-biblicos"}>Estudios Biblicos</NavItem>
          <NavItem url={"/contacto"}>Contacto</NavItem>
        </Right>
      </Bar>
    );
  }
}
