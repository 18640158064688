import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Container = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  height: 60px;
  color: #fff;

  &:hover {
    color: gold;
  }
`;

const activeStyle = {
  color: "gold"
};

export default class NavItem extends React.Component {
  render() {
    const { children, url } = this.props;

    return (
      <Container activeStyle={activeStyle} to={url}>
        {children}
      </Container>
    );
  }
}
