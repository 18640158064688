import Typography from "typography";

const typography = new Typography({
  title: "IRMP",
  baseFontSize: "16px",
  baseLineHeight: 1.7,
  scaleRatio: 2,
  googleFonts: [
    {
      name: "Poppins",
      styles: ["400", "600", "700"]
    }
  ],
  headerFontFamily: ["Poppins", "Helvetica", "Arial", "sans-serif"],
  bodyFontFamily: ["Poppins", "Helvetica", "Arial", "sans-serif"],
  bodyColor: "#000",
  headerWeight: 700,
  bodyWeight: 400,
  boldWeight: 600,
  overrideStyles: ({ rhythm }) => ({
    button: {
      outline: "none"
    },
    a: {
      textDecoration: "none",
      outline: "none"
    },
    iframe: {
      marginBottom: 0
    }
  })
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
