import React, { Component } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Spacer = styled.div`
  display: flex;
  width: 100%;
  height: 115px;

  ${props => props.theme.breakpoints.down("sm")} {
    height: 80px;
  }
`;

const StyledContent = styled.div`
  display: flex;
  padding: 0 20px;
  width: 100%;

  ${props => props.theme.breakpoints.up("sm")} {
    padding: 0 12px;
    width: ${props =>
      Math.round(props.theme.breakpoints.width("sm") * 0.975)}px;
  }

  ${props => props.theme.breakpoints.up("md")} {
    width: ${props =>
      Math.round(props.theme.breakpoints.width("md") * 0.975)}px;
  }

  ${props => props.theme.breakpoints.up("lg")} {
    width: ${props => Math.round(props.theme.breakpoints.width("lg") * 0.8)}px;
  }
`;

export default class extends Component {
  static defaultProps = {
    spacer: true
  };

  render() {
    return (
      <Container>
        {this.props.spacer && <Spacer />}
        <StyledContent {...this.props} />
      </Container>
    );
  }
}
