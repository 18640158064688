import styled from "styled-components";

export default styled.div`
  color: #000;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  line-height: 1.1;
  padding-bottom: 10px;
  border-bottom: 1px solid #016597;
  margin-bottom: 20px;
`;
