import React from "react";
import styled from "styled-components";

import SocialLinks from "./SocialLinks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  color: #000;
  letter-spacing: 0.1rem;
  margin-bottom: 20px;
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export default () => {
  return (
    <Container>
      <Title>{"Redes Sociales"}</Title>
      <Links>
        <SocialLinks />
      </Links>
    </Container>
  );
};
