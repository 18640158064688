import React, { Component } from "react";
import styled from "styled-components";
import BurgerMenu from "react-burger-menu";
import { Link } from "gatsby";

import Root from "./Root";
import globalState from "../utils/globalState";
import Popup from "./Popup";
import * as PropTypes from "prop-types";

const Wrapper = styled.div`
  outline: none;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

const StyledLink = styled(Link)`
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px 10px;
  cursor: pointer;
  color: #fff;

  &:hover {
    color: gold;
  }
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  padding: 0 34px;
  height: 80px;
  box-shadow: ${props => props.theme.shadows[5]};
`;

const Name = styled.div`
  font-weight: 600;
  color: #016597;
`;

const menuStyles = {
  bmOverlay: {
    zIndex: 2000
  },
  bmMenuWrap: {
    zIndex: 2100,
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.1), 0px 5px 8px 0px rgba(0,0,0,0.07), 0px 1px 14px 0px rgba(0,0,0,0.06)"
  },
  bmMenu: {
    background: "#016597"
  },
  bmBurgerButton: {
    zIndex: 2000,
    position: "fixed",
    width: "24px",
    height: "20px",
    left: "24px",
    top: "29px"
  },
  bmBurgerBars: {
    background: "#6b6b6b"
  },
  bmCross: {
    background: "#6b6b6b"
  }
};

const activeStyle = {
  color: "gold"
};

export default class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupHidden: false
    };
  }

  render() {
    let { children } = this.props;
    let popupHidden = globalState.popupHidden;

    return (
      <Root>
        <BurgerMenu.slide styles={menuStyles}>
          <Wrapper>
            <NameContainer>
              <Name>Iglesia Remanente</Name>
              <Name>Metodista Pentecostal</Name>
            </NameContainer>
          </Wrapper>
          <Wrapper>
            <Links>
              <StyledLink activeStyle={activeStyle} to="/">
                Inicio
              </StyledLink>
              <StyledLink activeStyle={activeStyle} to="/nuestros-templos">
                Nuestros Templos
              </StyledLink>
              <StyledLink activeStyle={activeStyle} to="/coros">
                Coros
              </StyledLink>
              <StyledLink activeStyle={activeStyle} to="/galeria">
                Galería
              </StyledLink>
              <StyledLink activeStyle={activeStyle} to="/estudios-biblicos">
                Estudios Biblicos
              </StyledLink>
              <StyledLink activeStyle={activeStyle} to="/nuestra-historia">
                Nuestra Historia
              </StyledLink>
              <StyledLink activeStyle={activeStyle} to="/contacto">
                Contacto
              </StyledLink>
            </Links>
          </Wrapper>
        </BurgerMenu.slide>
        {children}
        <Popup
          hide={popupHidden}
          onHide={() => {
            globalState.popupHidden = true;
            this.setState({ popupHidden: true });
          }}
        />
      </Root>
    );
  }
}

Layout.propTypes = { children: PropTypes.any };
