import React from "react";
import styled, { css } from "styled-components";
import showdown from "showdown";

const converter = new showdown.Converter();

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  transition: ${props => props.theme.transitions.create()};

  ${props =>
    props.isHidden &&
    css`
      display: none;
    `}
`;

const Center = styled.div`
  position: relative;
  background-color: #fff;
  padding: 20px 20px 0;
  border-radius: 10px;
  border: gold 2px solid;
  box-shadow: ${props => props.theme.shadows[5]};
  width: 640px;

  @media (max-width: 711px) {
    width: 90%;
  }
`;

const Border = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: #fff solid 20px;
  border-bottom: rgba(255, 255, 255, 0.6) solid 40px;
  border-radius: 10px;
`;

const Title = styled.div`
  color: #000;
  font-size: 28px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  line-height: 1.1;
  padding-bottom: 10px;
  border-bottom: 1px solid #016597;
  margin-bottom: 20px;
`;

const Body = styled.div`
  max-height: 300px;
  overflow: scroll;
  margin: 20px 20px 0;

  & > p {
    margin-bottom: 1rem;
  }
`;

const b = `Estimados hermanos y hermanas.

Se les informa que a partir de hoy, miércoles 18 de marzo de 2020, y hasta nuevo aviso, se suspenden todos los servicios de nuestra iglesia, tanto en nuestro Templo Catedral, como en las diferentes clases.

A su vez se les invita a sintonizar nuestro canal de youtube "Iglesia Remanente Metodista Pentecostal", el día domingo 22 de marzo a las 11:00 horas, para conocer el mensaje de la escuela dominical y luego, en la tarde a las 19:00 horas, para conocer el mensaje de la palabra del Señor.`;

const pad = '<div style="width: 100%; height: 40px;" />';

export default class Popup extends React.Component {
  render() {
    const { hide } = this.props;

    return (
      <Background isHidden={hide} onClick={() => this._close()}>
        <Center>
          <Title>{"Aviso"}</Title>
          <Body
            dangerouslySetInnerHTML={{
              __html: converter.makeHtml(b) + pad
            }}
          />
          <Border />
        </Center>
      </Background>
    );
  }

  _close() {
    this.props.onHide();
  }
}
