import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import createTheme from "./styles/createTheme";
import common from "./colors/common";

const theme = createTheme({
  palette: {
    background: {
      default: common.white
    }
  }
});

const GlobalStyle = createGlobalStyle`
  html {
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	  box-sizing: border-box;
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }
  body {
    margin: 0;
    background-color: #fff;
    @media print {
      background-color: #fff;
    }
  }
  
  .slick-prev {
    z-index: 999;
    left: 25px !important;
  }
  
  .slick-next {
    z-index: 999;
    right: 25px !important;
  }
`;

export default ({ children }) => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <GlobalStyle />
      {children}
    </React.Fragment>
  </ThemeProvider>
);
