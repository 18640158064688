import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import Window from "@tleef/react-window";

import MiniAbout from "./MiniAbout";
import MiniSocial from "./MiniSocial";
import MiniSchedule from "./MiniSchedule";
import MiniDirections from "./MiniDirections";

const StyledMediaBar = styled.div`
  position: relative;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MiniSpacer = styled.div`
  height: 10px;
`;

export default class SideBar extends React.Component {
  constructor(props) {
    super(props);

    this.container = React.createRef();
    this.content = React.createRef();

    this.state = {
      fixedTop: false,
      fixedBottom: false,
      style: null
    };
  }

  computeOriginalTop() {
    if (this.content.current) {
      this.originalTop =
        this.content.current.getBoundingClientRect().top + window.scrollY;
    }
  }

  render() {
    const { style } = this.state;

    return (
      <Window
        onScroll={() => this.onWindowScroll()}
        onResize={() => this.onWindowResize()}
      >
        <StyledMediaBar>
          <Container style={style} ref={this.container}>
            <Content
              ref={el => {
                this.content.current = el;

                if (!this.originalTop) {
                  this.computeOriginalTop();
                }
              }}
            >
              {this.props.spacer && <MiniSpacer />}
              {this.props.about && <MiniAbout />}
              {this.props.social && <MiniSocial />}
              {this.props.schedule && <MiniSchedule />}
              {this.props.directions && <MiniDirections />}
            </Content>
          </Container>
        </StyledMediaBar>
      </Window>
    );
  }

  onWindowResize() {
    const { style } = this.state;

    if (style && window.innerWidth < 960) {
      this.setState({
        fixedTop: false,
        fixedBottom: false,
        style: null
      });
    }
  }

  onWindowScroll() {
    let container = this.container.current;
    let content = this.content.current;

    if (!container || !content) {
      return;
    }

    const { fixedTop, fixedBottom, style } = this.state;

    if (!style) {
      this.computeOriginalTop();
    }

    const deadScrollSpace = this.originalTop - this.props.fixTop;
    const scrollY = window.scrollY;
    const lastScrollY = this.lastScrollY;
    this.lastScrollY = scrollY;

    if (lastScrollY == null) {
      return;
    }

    const body = document.body;
    const html = document.documentElement;

    const bounds = content.getBoundingClientRect();
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const barHeight = bounds.height + this.originalTop + this.props.fixBottom;

    const windowTooNarrow = window.innerWidth < 960;
    const barHeightNearDocHeight = docHeight - barHeight < 2;
    const inDeadScrollSpace = window.scrollY < deadScrollSpace;

    if (windowTooNarrow || barHeightNearDocHeight || inDeadScrollSpace) {
      if (style) {
        // use default layout
        this.setState({
          fixedTop: false,
          fixedBottom: false,
          style: null
        });
      }

      return;
    }

    // media bar is too short for window
    if (
      this.props.fixTop + bounds.height + this.props.fixBottom <
      window.innerHeight
    ) {
      if (!fixedTop) {
        this.setState({
          fixedTop: true,
          fixedBottom: false,
          style: {
            position: "fixed",
            top: this.props.fixTop,
            width: bounds.width
          }
        });
      }
      return;
    }

    // fixed to top and scrolling down
    if (fixedTop && scrollY > lastScrollY) {
      // unfix
      return this.setState({
        fixedTop: false,
        fixedBottom: false,
        style: {
          position: "absolute",
          top: window.scrollY - deadScrollSpace,
          width: bounds.width
        }
      });
    } else if (fixedBottom && scrollY < lastScrollY) {
      // fixed to bottom and scrolling up
      // unfix from bottom
      return this.setState({
        fixedTop: false,
        fixedBottom: false,
        style: {
          position: "absolute",
          top:
            window.scrollY +
            window.innerHeight -
            bounds.height -
            this.originalTop -
            this.props.fixBottom,
          width: bounds.width
        }
      });
    } else if (!fixedBottom && !fixedTop) {
      // not fixed
      // aligned with top fix position
      if (bounds.top > this.props.fixTop && window.scrollY > deadScrollSpace) {
        // fix to top
        return this.setState({
          fixedTop: true,
          fixedBottom: false,
          style: {
            position: "fixed",
            top: this.props.fixTop,
            width: bounds.width
          }
        });
      } else if (bounds.bottom < window.innerHeight - this.props.fixBottom) {
        // aligned with bottom
        // fix to bottom
        return this.setState({
          fixedTop: false,
          fixedBottom: true,
          style: {
            position: "fixed",
            top: window.innerHeight - bounds.height - this.props.fixBottom,
            width: bounds.width
          }
        });
      }
    }
  }
}

SideBar.propTypes = {
  spacer: PropTypes.bool,
  about: PropTypes.bool,
  social: PropTypes.bool,
  directions: PropTypes.bool,
  schedule: PropTypes.bool,
  fixTop: PropTypes.number,
  fixBottom: PropTypes.number
};

SideBar.defaultProps = {
  spacer: true,
  about: true,
  social: true,
  directions: true,
  schedule: true
};
