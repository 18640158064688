import React from "react";
import styled from "styled-components";
import SocialLinks from "./SocialLinks";

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #016597;
  justify-content: center;
  margin-top: 50px;
  z-index: ${props => props.theme.zIndex.appBar - 2};
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 40px 20px;
  width: 100%;
  ${props => props.theme.breakpoints.up("sm")} {
    width: ${props =>
      Math.round(props.theme.breakpoints.width("sm") * 0.975)}px;
  }
  ${props => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    justify-content: space-between;
    width: ${props =>
      Math.round(props.theme.breakpoints.width("md") * 0.975)}px;
  }
  ${props => props.theme.breakpoints.up("lg")} {
    width: ${props => Math.round(props.theme.breakpoints.width("lg") * 0.8)}px;
  }
`;

const Copyright = styled.div`
  color: #fff;
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  ${props => props.theme.breakpoints.up("md")} {
    margin-bottom: 0;
  }
`;

export default () => (
  <Container>
    <Inner>
      <Copyright>
        Copyright © 2019 Iglesia Remanente Metodista Pentecostal
      </Copyright>
      <Links>
        <SocialLinks light />
      </Links>
    </Inner>
  </Container>
);
