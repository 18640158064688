import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
`;

const Title = styled(Link)`
  display: flex;
  justify-content: center;
  color: #000;
  letter-spacing: 0.1rem;
  margin-bottom: 20px;
`;

const ImageContainer = styled(Link)`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 3px;
  width: 100%;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;

const Image = styled(Img)`
  transition: ${props => props.theme.transitions.create()};
  transform: scale(1.03);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  &:hover {
    transform: scale(1.05);
  }
`;

export default () => {
  let link = `/nuestra-historia`;

  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <Container>
            <Title to={link}>{"Nuestra Historia"}</Title>
            <ImageContainer to={link}>
              <Image
                fluid={data.profilePic.childImageSharp.fluid}
                style={{ position: "absolute" }}
              />
            </ImageContainer>
          </Container>
        );
      }}
    />
  );
};

const query = graphql`
  query {
    profilePic: file(relativePath: { eq: "DSC_5548-square.png" }) {
      childImageSharp {
        fluid(maxWidth: 480, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
