import React from "react";
import styled from "styled-components";
import Window from "@tleef/react-window";

import Logo from "./Logo";
import Nav from "./Nav";
import SocialLinks from "./SocialLinks";

const ShortName = styled.div`
  font-weight: 600;
  color: #016597;
  z-index: ${props => props.theme.zIndex.appBar};
  position: fixed;
  left: 72px;
  top: 26px;
  @media (min-width: 650px) {
    display: none;
  }
`;

const Social = styled.div`
  display: flex;
  z-index: ${props => props.theme.zIndex.appBar};
  position: fixed;
  right: 24px;
  top: 20px;
  @media (min-width: 650px) {
    display: none;
  }
`;

const NameLeft = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  color: #016597;
  z-index: ${props => props.theme.zIndex.appBar};
  width: 200px;

  @media (max-width: 649.95px) {
    display: none;
  }
`;

const NameRight = styled.div`
  font-weight: 600;
  color: #016597;
  z-index: ${props => props.theme.zIndex.appBar};
  width: 200px;

  @media (max-width: 649.95px) {
    display: none;
  }
`;

const Bar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  position: fixed;
  width: 100%;
  height: 80px;
  background-color: ${props => props.theme.palette.background.default};
  z-index: ${props => props.theme.zIndex.appBar};
  box-shadow: ${props => props.theme.shadows[5]};
`;

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.bar = React.createRef();
    this.nav = React.createRef();

    this.state = {
      scrolled: false,
      prevScroll: 0,
      scrollDiff: 0,
      hideNav: false
    };

    this.onWindowScroll = this.onWindowScroll.bind(this);
  }

  render() {
    return (
      <Window onScroll={this.onWindowScroll}>
        <Bar ref={this.bar}>
          <ShortName>IRMP</ShortName>
          <Social>
            <SocialLinks
              noBorder
              highlightColor={"#016597"}
              lowlightColor={"#6b6b6b"}
            />
          </Social>
          <NameLeft>Iglesia Remanente</NameLeft>
          <Logo />
          <NameRight>Metodista Pentecostal</NameRight>
        </Bar>
        <Nav ref={this.nav} hide={this.state.hideNav} />
      </Window>
    );
  }

  onWindowScroll() {
    let bar = this.bar.current;
    let nav = this.nav.current;

    if (!bar || !nav) {
      return;
    }

    let scrolled = this.state.scrolled;
    let prevScroll = this.state.prevScroll;
    let hideNav = this.state.hideNav;

    let scroll = window.scrollY;
    let scrollDiff = scroll - prevScroll;

    let deadZone = 60;

    const state = {
      prevScroll: scroll,
      scrollDiff
    };

    if (!hideNav && scrollDiff > 0 && scroll > deadZone) {
      state.hideNav = true;
    } else if (hideNav && scrollDiff < 0) {
      state.hideNav = false;
    }

    if (!scrolled && scroll > deadZone) {
      state.scrolled = true;
      state.hideNav = true;
    } else if (scrolled && scroll <= deadZone) {
      state.scrolled = false;
      state.hideNav = false;
    }

    this.setState(state);
  }
}
